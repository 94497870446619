<template>
  <div class="technology container">

    <h1>Линия производства грунтосмесей</h1>
    <p style="color: red">фотографий гарадо больче чем текста (текста очень мало)</p>
    <p style="color: red">может добавить этапы сушки, гранулирования</p>

        <p>В практике аграрного сектора экономики разработано большое количество рецептур для получения почвенных смесей различного назначения.</p>
   <p>Для их получения предъявляются довольно жесткие требования как к компонентам смеси, так и к самой смеси. Это, прежде всего, крупность компонентов смеси и ее однородность.</p>

    <hr>

    <h5 style="color: red">Этап измельчение (добавить не большой зоголовок, например:)</h5>

    <p>
      Подготовка компонентов заключается, прежде всего, в их измельчении до требуемой крупности.
      Для этого нами разработаны дробилки для измельчения грунта, торфа, сапропеля, перегноя, перлита, вермикулита и других компонентов входящих в состав грунтовых смесей.
    </p>

    <p>
      <i style="text-decoration: line-through">На фото 1 и 2 показаны </i>дробилки для измельчения земли, торфа, сапропеля, перегноя.
    </p>

    <img src="~@/assets/img/oborudovanie/drobilka-rotorno-nozhevaya.jpg">

    <p>Дробилка,
      <i style="text-decoration: line-through">показанная на фото 1,</i> оснащена питателем для принудительной подачи измельчаемого продукта в дробилку. Размеры, например кусков торфа подаваемого на измельчение, могут достигать до 300 мм. Размеры частиц после измельчения составляют до 5 мм.
    </p>
    <img src="~@/assets/img/oborudovanie/melnitsy-valkovyie.jpg">

    <p>
      <i style="text-decoration: line-through">На фото 3, 4, 5 и 6 показаны</i> вальцевые мельницы для измельчения перлита, вермикулита и других аналогичных продуктов. Особенностью этих мельниц является то, что измельченный продукт содержит минимально возможное количество пылевидной фракции. Поверхность валков мельниц наплавляется твердым сплавом или имеет рифли. На всех моделях мельниц валковых зазор между валками регулируется в зависимости от требуемой крупность измельчения.
    </p>
    <hr>
<h5 style="color: red">Этап смешивания (добавить не большой зоголовок, например:)</h5>
    <p>После измельчения компонентов входящих в состав грунтовых смесей они подаются на смешивание.
      <b>На рисунке 1 приведена одна из возможных схем.</b> организации смешивания компонентов смеси.
    </p>
    <p>В приведенном варианте организации подачи компонентов, подача компонентов осуществляется посредством
      <router-link to="/oborudovanie/pitateli-shnekovyie">винтовых питателей</router-link>
       .
    </p>


    <p>
      Компоненты подаются в
      <router-link to="/oborudovanie/smesiteli-lopastnyie">смеситель непрерывного действия</router-link>
      .

       <img src="~@/assets/img/oborudovanie/smesiteli-lopastnyie.jpg">
      В зависимости от требований к грунтовой смеси, производительности и других требований применяются двухвальные лопастные смесители или одновальные лопастные смесители.
      <i style="text-decoration: line-through">На фото 9…15 показаны </i> несколько вариантов исполнения смесителей.
    </p>

    <hr>
     <h5 style="color: red">Этап просеивание (добавить не большой зоголовок, например:)</h5>
    <p>
      При подготовке компонентов смеси для смешивания, в зависимости от их свойств, для некоторых компонентов требуется их просеивание.
      В некоторых случаях требуется и просеивание готовой смеси.
      Для выполнения этой операции ООО «Проммаш» производит
      <router-link to="/oborudovanie/grohota-vysokochastotnyie">вибрационные высокочастотные грохота</router-link>
      с непосредственным вибрационным возбуждением ситовой ткани.

      <img src="~@/assets/img/oborudovanie/grohota-vysokochastotnyie.jpg">
      Грохота предназначены для классификации различных материалов по крупности разделения от 0,2 мм до 7 мм.
      Отличительными особенностями таких грохотов является малая мощность виброприводов, а для их эксплуа тации не требуется устройства специальных фундаментов.
    </p>


  </div>
</template>

<script>
  export default {

    components: {},

    mixins: [],

    props: {},

    data() {
      return {};
    },

    computed: {},

    watch: {},

    created() {
    },

    methods: {},
  };
</script>

<style scoped>

</style>